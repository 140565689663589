import _ from 'lodash';
import moment, { Moment, MomentInput } from 'moment';

import assert from 'common/assertions/assert';
import { getStartOfQuarterMonth } from 'common/dates';

import DateRange from './dateRange';
import { PeriodTypes, PeriodSizes, StartDateTypes, TimelineSamplingSizes } from './constants';

import { ReportingPeriod as ReportingPeriodConfig } from '../types';

export function isFullyConfigured(reportingPeriodConfig: ReportingPeriodConfig) {
  if (!_.isObject(reportingPeriodConfig) || _.isEmpty(reportingPeriodConfig) || !reportingPeriodConfig) {
    return false;
  }

  const { type, size, startDateConfig } = reportingPeriodConfig;

  if (!(type && size && startDateConfig)) {
    return false;
  }

  return true;
}

// Returns true if the given reportingPeriodConfig is fully specified
// and valid.
export function isConfigValid(reportingPeriodConfig?: ReportingPeriodConfig) {
  if (!reportingPeriodConfig || !isFullyConfigured(reportingPeriodConfig)) {
    return false;
  }

  const { type, size, endsBeforeDate } = reportingPeriodConfig;
  const startDateType = _.get(reportingPeriodConfig, 'startDateConfig.type');
  const startDate = getStartDate(reportingPeriodConfig);

  if (!startDate.isValid() && startDateType !== StartDateTypes.FLOATING) {
    return false;
  }

  if (
    endsBeforeDate &&
    (!moment(endsBeforeDate).isValid() ||
      (startDate.isAfter(endsBeforeDate) && startDateType !== StartDateTypes.FLOATING))
  ) {
    return false;
  }

  if (!_.includes(PeriodTypes, type) || !_.includes(PeriodSizes, size)) {
    return false;
  }

  return true;
}

// Wraps isConfigValid in an assert.
export function assertConfigValid(reportingPeriodConfig: ReportingPeriodConfig) {
  assert(isConfigValid(reportingPeriodConfig), 'Invalid reporting period configuration');
}

/**
 * Sampling size must be smaller than the current reporting period, and we
 * only want to show two options (because overly granular sampling, such as
 * "day" sampling against a "year" reporting period, makes the visualization
 * too messy). Note that this relies on TimelineSamplingSizes and
 * PeriodSizes having the same units, but shifted by one.
 */
export function getValidTimelineSamplingSizes(reportingPeriodSize: PeriodSizes): PeriodSizes[] {
  return _(TimelineSamplingSizes)
    .drop(_.indexOf(_.values(PeriodSizes), reportingPeriodSize))
    .take(2)
    .value();
}

/**
 *
 * @param start Typically the measure start date
 * @param size
 * @param date The last date that should be included within the series. This date
 *             will be part of the last reporting period returned.
 */
export function seriesThroughDate(
  start: MomentInput,
  size: PeriodSizes,
  date: MomentInput | null
): DateRange[] {
  if (!date) {
    return [];
  }

  const dateMoment = moment(date);
  const series = [];
  let x = new DateRange({ start, size });
  while (dateMoment.isSameOrAfter(x.start) || dateMoment.isSame(x.inclusiveEnd())) {
    series.push(x);
    x = x.next();
  }

  return series;
}

/**
 * Generates the floating start date based on the reporting peroid
 * config and the lastDate.
 *
 * NOTE: If lastDate is null (for example if the measure is last reported
 * and the calculation is not configured), then this will calculate from today.
 */
export function getFloatingStartDate(reportingPeriodConfig: ReportingPeriodConfig, lastDate?: Moment | null) {
  const duration: number = _.get(reportingPeriodConfig, 'startDateConfig.duration');

  // lastDate is the last day included in the measure, so we subtract 1 from
  // the duration when calculating the start date
  return moment(lastDate)
    .subtract(duration - 1, 'days')
    .startOf('day');
}

/**
 * Generates the start date for non-floating types
 */
export function getStartDate(reportingPeriodConfig: ReportingPeriodConfig): Moment {
  return moment(_.get(reportingPeriodConfig, 'startDateConfig.date'));
}

/**
 * Get the last reporting period that is allowed given the configuration of the
 * measure. NOTE: This specifically does NOT take the last data date into account
 * because we need the result of _this_ function in order to accurately calculate
 * the last data date.
 */
export function getLastAllowedDate(
  reportingPeriodConfig: ReportingPeriodConfig,
  getReportingPeriodStartContainingDateFun = getReportingPeriodStartContainingDate //For test injection
): Moment {
  const { type, size, endsBeforeDate } = reportingPeriodConfig;
  const today = moment();
  const hasMeasureEnded = today.isAfter(endsBeforeDate);
  let lastDay: Moment;

  if (hasMeasureEnded) {
    /**
     * For daily reporting periods, the endsBeforeDate is the real
     * end date of the measure rather than 1 day after the end date
     * e.g. User selects 2020 as the measure end date for a yearly
     * measure, the endsBeforeDate is 2021-01-01. For a daily measure
     * user selects 2020-12-31 as the measure end date, the endsBeforeDate
     * is 2020-12-31
     */
    if (size === PeriodSizes.DAY) {
      lastDay = moment(endsBeforeDate);
    } else {
      lastDay = moment(endsBeforeDate).subtract(1, 'day');
    }
  } else {
    switch (type) {
      case PeriodTypes.OPEN:
        lastDay = today;
        break;
      case PeriodTypes.CLOSED:
      case PeriodTypes.LAST_REPORTED:
        // Get the start of the current reporting period
        // TypeScript note: getReportingPeriodStartContainingDate should
        // return a non-null value as long as the second parameter is not null.
        // TS can't figure that out (yet!), so the ! asserts it.
        const startOfCurrentReportingPeriod = getReportingPeriodStartContainingDateFun(
          reportingPeriodConfig,
          today
        )!;
        // Get the end of the prior reporting period
        lastDay = moment(startOfCurrentReportingPeriod).subtract(1, 'day');
        break;
      default:
        throw new Error(`Cannot find last allowed reporting period for ${type} type.`);
    }
  }

  return lastDay.endOf('day');
}

// Checks to see if there is a current reporting period. If there is not, don't render the
// card. Solves issue with trying to reportingPeriod start in the future.
export function isStartDateValid(reportingPeriodConfig: ReportingPeriodConfig): boolean {
  const now = moment();
  const startDuration = _.get(reportingPeriodConfig, 'startDateConfig.duration');
  return (
    reportingPeriodConfig.startDateConfig &&
    (moment(reportingPeriodConfig.startDateConfig.date).isSameOrBefore(now) || startDuration)
  );
}

/**
 * This is meant to be used to get the last reporting period for OPEN measures only. It is
 * used for the timeline chart scope feature.
 */
export function getLastOpenReportingPeriod(
  reportingPeriodConfig: ReportingPeriodConfig
): DateRange | undefined {
  return _.last(getSeries(reportingPeriodConfig, moment()));
}

export function getSeries(reportingPeriodConfig: ReportingPeriodConfig, lastDate?: Moment | null) {
  assertConfigValid(reportingPeriodConfig);

  const endDate = lastDate ? moment(lastDate).endOf('day') : null;
  const size = reportingPeriodConfig.size!;

  const startDateType = _.get(reportingPeriodConfig, 'startDateConfig.type');
  let startDate;

  if (startDateType === StartDateTypes.FLOATING) {
    startDate = getFloatingStartDate(reportingPeriodConfig, lastDate);
  } else {
    startDate = getStartDate(reportingPeriodConfig);
  }

  return seriesThroughDate(startDate, size, endDate);
}

/**
 * Given a measure and a date, returns the start of the reporting period which
 * contains that date. If the reporting period of the measure is not fully
 * configured, it just returns the date.
 */
export const getReportingPeriodStartContainingDate = (
  reportingPeriod: ReportingPeriodConfig,
  date?: Moment
) => {
  if (!date) {
    return null;
  }

  const startDate = reportingPeriod.startDateConfig?.date;

  const { firstQuarterStartMonth, size } = reportingPeriod;

  // Measure must be configured and have a fixed startDate)
  if (!size || !startDate) {
    return date;
  }

  // If the requested date is before the start date, default to the start date
  if (moment(date).isSameOrBefore(startDate)) {
    return startDate;
  }

  let correctedDate: Moment;

  switch (size) {
    case PeriodSizes.DAY: {
      throw new Error('Day reporting periods should not have a fixed start date');
    }
    case PeriodSizes.WEEK: {
      const dateRange = _.last(seriesThroughDate(startDate, size, date));
      correctedDate = moment(dateRange!.start);
      break;
    }
    case PeriodSizes.MONTH:
      correctedDate = moment(date).startOf('month');
      break;
    case PeriodSizes.QUARTER: {
      const dateMoment = moment(date);
      const correctedMonth = getStartOfQuarterMonth(dateMoment.month(), firstQuarterStartMonth);
      correctedDate = dateMoment.month(correctedMonth).startOf('month');
      if (correctedMonth > 9) {
        // This ensures that we don't leap ahead 3 quarters instead of back 1 quarter
        correctedDate.subtract(1, 'year');
      }
      break;
    }
    case PeriodSizes.YEAR: {
      const dateMoment = moment(date);
      const startMoment = moment(startDate);

      // This ensures if the reporting period for a year is offset to another month other than January
      // we don't skip the latest reporting period in cases where the month of the date passed in
      // is greater than the month of the reporting period
      if (startMoment.month() > dateMoment.month()) {
        dateMoment.subtract(1, 'year');
      }

      correctedDate = dateMoment.month(moment(startDate).month()).startOf('month');

      break;
    }
    default:
      throw new Error('Unrecognized reporting period size');
  }

  return correctedDate.format('YYYY-MM-DD');
};

// TODO: How many place do we implement this :(
export const getDisplayDateRange = (
  date: string,
  cumulativeMathStartDate: string | undefined,
  isCumulativeMath: boolean,
  periodSize: PeriodSizes | undefined
): string => {
  if (!periodSize) {
    return '';
  }

  let rangeEnd;
  let rangeStart;

  if (periodSize === PeriodSizes.DAY) {
    if (isCumulativeMath) {
      rangeStart = moment(cumulativeMathStartDate).format('M/D/YY');
      rangeEnd = moment(date).format('M/D/YY');
    } else {
      rangeStart = moment(date).format('M/D/YY');
    }
  } else {
    if (isCumulativeMath) {
      rangeStart = moment(cumulativeMathStartDate).format('M/D/YY');
    } else {
      rangeStart = moment(date).format('M/D/YY');
    }

    rangeEnd = moment(date).add(1, periodSize).subtract(1, 'day').format('M/D/YY');
  }

  return rangeStart && rangeEnd ? `${rangeStart} - ${rangeEnd}` : rangeStart;
};
