// Internal helpers for measureCalculator.

import _ from 'lodash';
import moment, { Moment } from 'moment';

import assert from 'common/assertions/assert';
import { SoqlHelpers } from 'common/visualizations/dataProviders';
import SoqlDataProvider from 'common/visualizations/dataProviders/SoqlDataProvider';
import { ViewColumn } from 'common/types/viewColumn';
import { SoqlFilter } from 'common/components/FilterBar/SoqlFilter';

import { CalculationTypes, PeriodTypes, StartDateTypes } from '../lib/constants';
import { isPercentColumn } from '../lib/percents';
import * as ReportingPeriods from '../lib/reportingPeriods';
import { Measure, MeasureArgumentName } from '../types';

/**
 * @returns true if the given column can be used with the given measure, false otherwise.
 */
export const isColumnUsableWithMeasureArgument = (
  column: ViewColumn,
  measure: Measure,
  argument: MeasureArgumentName
) => {
  if (!column) {
    return false;
  }

  const type = _.get(measure, 'metricConfig.type');
  const renderTypeName = _.get(column, 'renderTypeName');
  // TS shows that renderTypeName can never be 'money', but I'm not willing to change the code if it works.
  // @ts-expect-error TS(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
  const columnIsNumeric = renderTypeName === 'number' || renderTypeName === 'money';
  const columnIsPercent = isPercentColumn(column);

  if (argument === 'dateColumn') {
    return renderTypeName === 'calendar_date';
  }

  if (type === CalculationTypes.RECENT) {
    // Special enough to be clearer as a separate path.
    return argument === 'valueColumn' && columnIsNumeric;
  } else {
    // All other types
    const aggregationType = _.get(measure, 'metricConfig.arguments.aggregationType');
    const needsNumericColumn = measureArgumentNeedsNumericColumn(measure, argument);
    const canUsePercentColumn = type !== CalculationTypes.RATE || aggregationType !== CalculationTypes.SUM;

    return (columnIsNumeric || !needsNumericColumn) && (canUsePercentColumn || !columnIsPercent);
  }
};

export const measureArgumentNeedsNumericColumn = (measure: Measure, argument: MeasureArgumentName) => {
  const type = _.get(measure, 'metricConfig.type');
  const aggregationType = _.get(measure, 'metricConfig.arguments.aggregationType');
  return (
    argument !== 'dateColumn' &&
    ((type === CalculationTypes.RATE && aggregationType === CalculationTypes.SUM) ||
      _.includes([CalculationTypes.AVERAGE, CalculationTypes.RECENT, CalculationTypes.SUM], type))
  );
};

export const setupSoqlDataProvider = (measure: Measure) => {
  const datasetUid = _.get(measure, 'dataSourceLensUid');
  const domain = _.get(measure, 'domain', window.location.hostname);
  if (!datasetUid) {
    return null;
  }

  const dataProviderConfig = { domain, datasetUid };

  return new SoqlDataProvider(dataProviderConfig);
};

/* Helper functions. Should use BigNumbers where possible. */

/**
 * When a filter is added to a measure but no selection on the filter is made, a default 'noop' filter
 * is inserted into the array of filters. However when persisting this to the database, the `arguments` key,
 * which is required, gets dropped since it is set to `null` by default. This re-adds the nulls to the filter
 * objects
 */
export const addArgumentsToFilter = (filter: SoqlFilter) => {
  if (!filter.arguments) {
    _.set(filter, 'arguments', null);
  }
  return filter;
};

export const filterWhereClauses = (filters: SoqlFilter[]): string[] => {
  return _(filters).map(addArgumentsToFilter).map(SoqlHelpers.filterToWhereClauseComponent).value();
};

export const joinWhereClauses = (whereClauses: (string[] | string | undefined | null)[]) =>
  _(whereClauses).flatten().compact().join(' AND ');

/**
 * Gets the latest (i.e., greatest magnitude) date value from dateColumn, matching
 * the given WHERE clauses.
 */
export const lastDataTime = async (
  dataProvider: SoqlDataProvider,
  dateColumn: string,
  whereClauses: (string[] | string | undefined | null)[]
): Promise<Moment | null> => {
  assert(whereClauses.length > 0, 'At least one where clause must be supplied.');

  const dateColumnEncoded = SoqlHelpers.soqlEncodeColumnName(dateColumn);

  const dateAlias = '__measure_date_alias__';

  let query;

  // In cases of floating start dates (e.g. startDateConfig.type === 'floating')
  // the whereClauses is empty
  if (_.isEmpty(_.compact(whereClauses))) {
    query = `select max(${dateColumnEncoded}) as ${dateAlias}`;
  } else {
    query = `select max(${dateColumnEncoded}) as ${dateAlias} where ${joinWhereClauses(whereClauses)}`;
  }

  const data = await dataProvider.rawQuery(query);
  const date = _.get(data, ['0', dateAlias]);
  return date ? moment(date) : null;
};

/**
 * Gets the latest (i.e., greatest magnitude) value from the measure's dateColumn,
 * respecting the measure's filters and the given dateRangeWhereClause.
 * Returns a hash:
 * {
 *   errors: {..}  // Any errors encountered. Only possible key is calculationNotConfigured.
 *   result: {
 *     value: moment or null
 *   }
 * }
 */
export const getLastDataTime = async (
  measure: Measure,
  dataProvider: SoqlDataProvider | null = setupSoqlDataProvider(measure), // For test injection
  lastDataTimeFun = lastDataTime // For test injection
) => {
  const dateColumn = _.get(measure, 'metricConfig.dateColumn');
  const calculationType = _.get(measure, 'metricConfig.type');
  const columnKey =
    calculationType === CalculationTypes.RECENT
      ? 'metricConfig.arguments.valueColumn'
      : 'metricConfig.arguments.column';
  const valueColumn = _.get(measure, columnKey);
  const isCalculationConfigured = !!dateColumn || !!valueColumn;

  if (!isCalculationConfigured) {
    return null;
  }

  const reportingPeriodConfig = _.get(measure, 'metricConfig.reportingPeriod', {});
  const lastAllowedDate = ReportingPeriods.getLastAllowedDate(reportingPeriodConfig);

  if (reportingPeriodConfig.type !== PeriodTypes.LAST_REPORTED) {
    // For Open and Closed reporting periods, there's no need to calculate this based on the data.
    return lastAllowedDate;
  }

  const calculationFilters = _.get(measure, 'metricConfig.arguments.calculationFilters', []);
  const calculationWhereClause = _.isEmpty(calculationFilters)
    ? null
    : filterWhereClauses(calculationFilters);
  const columnWhereClause = valueColumn
    ? `${SoqlHelpers.soqlEncodeColumnName(valueColumn)} IS NOT NULL`
    : null;

  const endDateSoql = SoqlHelpers.soqlEncodeValue(lastAllowedDate.toDate());
  const column = SoqlHelpers.soqlEncodeColumnName(dateColumn);
  const dateRangeEndWhereClause = `${column} <= ${endDateSoql}`;

  let dateRangeStartWhereClause;
  if (reportingPeriodConfig.startDateConfig.type === StartDateTypes.FIXED) {
    const startDateSoql = SoqlHelpers.soqlEncodeValue(
      moment(reportingPeriodConfig.startDateConfig.date).toDate()
    );
    const encodedColumn = SoqlHelpers.soqlEncodeColumnName(dateColumn);
    dateRangeStartWhereClause = `${encodedColumn} >= ${startDateSoql}`;
  }

  let value: Moment | null = null;

  if (dataProvider) {
    value = await lastDataTimeFun(dataProvider, dateColumn, [
      calculationWhereClause,
      columnWhereClause,
      dateRangeStartWhereClause,
      dateRangeEndWhereClause
    ]);
  }

  return value;
};

/** Helpers for getMeasureConfigurationErrors */
const noReportingPeriodConfigured = (measure: Measure) => {
  const reportingPeriod = measure.metricConfig?.reportingPeriod;
  return !ReportingPeriods.isConfigValid(reportingPeriod);
};

const calculationNotConfigured = (measure: Measure) => {
  if (!_.get(measure, 'metricConfig.dateColumn')) {
    // All calculation types need this
    return true;
  }

  const calculationType = _.get(measure, 'metricConfig.type');
  switch (calculationType) {
    case CalculationTypes.COUNT:
      return false; // doesn't need additional configuration
    case CalculationTypes.AVERAGE:
    case CalculationTypes.SUM:
      return !_.get(measure, 'metricConfig.arguments.column');
    case CalculationTypes.RECENT:
      return !_.get(measure, 'metricConfig.arguments.valueColumn');
    case CalculationTypes.RATE: {
      const { aggregationType, numeratorColumn, denominatorColumn, fixedDenominator } = _.get(
        measure,
        'metricConfig.arguments',
        {}
      );

      if (!aggregationType) {
        return true;
      } else if (aggregationType === CalculationTypes.SUM) {
        // Note that we still want to calculate partial rates. calculateRateMeasure
        // is responsible for setting this error if there is a partial configuration.
        return !numeratorColumn && !denominatorColumn && !fixedDenominator;
      } else if (aggregationType === CalculationTypes.COUNT) {
        return false; // count aggregation type has no additional configuration
      }
    }
  }

  // We should never get here, but if we do then there's
  // something wrong with the calculation configuration.
  return true;
};

const noReportingPeriodAvailable = (measure: Measure, lastDataMoment: Moment | null) => {
  const reportingPeriodConfig = _.get(measure, 'metricConfig.reportingPeriod');
  const reportingPeriods = ReportingPeriods.getSeries(reportingPeriodConfig, lastDataMoment);
  return reportingPeriods.length <= 0;
};

/**
 * Gets configuration errors for a measure. Used to determine whether
 * to continue and calculate a measure, or to just skip it because
 * the calculation would be broken. This method will return early if
 * an error is discovered that makes other errors irrelevant.
 */
export const getMeasureConfigurationErrors = (measure: Measure, lastDataMoment: Moment | null) => {
  const errors = _.pickBy({
    noReportingPeriodConfigured: noReportingPeriodConfigured(measure),
    dataSourceNotConfigured: !_.get(measure, 'dataSourceLensUid')
  });

  if (Object.keys(errors).length > 0) {
    return errors;
  }

  if (calculationNotConfigured(measure)) {
    errors.calculationNotConfigured = true;
    return errors;
  }

  if (noReportingPeriodAvailable(measure, lastDataMoment)) {
    errors.noReportingPeriodAvailable = true;
  }

  return errors;
};
